.event-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Add this line if you want a max width constraint */
    margin: 0 auto; /* Center on page if there's max-width */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.event-page-container-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(80, 52, 89);
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
}
.event-logo {
	display: flex;
}
.event-logo > img {
	height: min(32vw, 500px);
}

.description-container {
	display: flex;
	flex-direction: column;
	height: min(32vw, 150px);
	border-radius: 4px;
	background-color: rgb(119, 153, 125);
}

.event-caption {
	padding-bottom: 10px;
}
.button-div {
    display: flex;
    align-items: center;            /* Vertically center items */
    gap: 10px;                      /* Gap between buttons */
    width: 100%;                    /* Ensure it takes the full width of the container */
    max-width: 800px;               /* Optional: set a max-width for better alignment */
    margin: 0 auto;                 /* Center the button container horizontally */
}

.button-div > .link {
    background-color: rgb(82, 97, 171);
    color: antiquewhite;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;             /* Adjust padding for consistent size */
    text-align: center;
    font-size: 16px;                /* Fixed font size for consistency */
    text-decoration: none;
    transition-duration: 0.4s;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-shadow: 2px 1px 2px black;
    font-weight: bold;
    width: 30%;                     /* Make each button 30% of the container width */
    min-width: 150px;               /* Ensure buttons don't get too small on mobile */
    text-align: center;
}

@media (max-width: 600px) {
    .button-div {
        flex-direction: column;  /* Stack buttons vertically */
        align-items: center;     /* Center buttons horizontally */
    }

    .button-div > .link {
        width: 100%;             /* Make buttons take full width on small screens */
        margin-bottom: 10px;     /* Space between stacked buttons */
    }
}



.button-div > .link:hover {
	color: rgb(65, 65, 65);
	text-decoration: none;
}

.button-div > .dead {
	width: 80%;
	background-color: darkslategray;
	color: antiquewhite;
	border: none;
	border-radius: 10px;
	padding: 8px 16px;
	text-align: center;
	font-size: min(5vw, 24px);
	text-decoration: none;
	transition-duration: 0.4s;
	cursor: pointer;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-shadow: 2px 1px 2px black;
	font-weight: bold;
}

.button-div > .dead:hover {
	color: red;
	text-decoration: none;
}

.registration-container > div > button:hover {
	background-color: rgb(39, 53, 12);
	color: darkslategray;
}
.video-frame {
    width: 100%; /* Full width for responsiveness */
    max-width: 560px; /* Restricts width on larger screens */
    aspect-ratio: 16 / 9; /* Ensures correct video proportions */
    border-radius: 10px; /* Optional: rounded corners */
}

/* Ensures proper spacing and centering */
.event-page-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(69, 48, 73);
    width: 100%;
    margin: 10px auto 30px auto; /* Centers horizontally and adds vertical spacing */
    padding: 10px; /* Adds inner spacing */
    border-radius: 15px;
}
.event-desc {
    max-width: 600px; /* Prevents overly wide text */
    text-align: center; /* Centers text */
    margin-top: 15px; /* Adds spacing from video */
    padding: 10px;
    color: antiquewhite; /* Adjust for readability */
    font-size: 1rem;
    line-height: 1.4;
}
.event-desc-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 15px;
	max-width: 600px;
	line-height: 1.2;
	border-radius: 16px;
}
.event-desc-header,
.attendees-header {
	display: flex;
	margin-top: 8px;
	font-size: 24px;
	text-align: left;
	font-weight: bold;
}
.event-desc-body {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 16px;
	padding: 0 10px;
	text-shadow: none;
	/* max-height: 550px;
    overflow: auto; */
}
p {
	margin: 8px 0;
}
.attendees-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	border-radius: 0;
	min-width: min-content;
	align-items: center;
}

.message-container {
	color: rgb(255, 8, 0);
	font-weight: bold;
	font-size: min(10.5vw, 44px);
	margin: 5px;
}
.list-container-overflow {
	background-color: rgb(65, 65, 65);
	border-radius: 5px;
	min-height: 400px;
	max-height: 600px;
	overflow: auto;
	margin-bottom: 20px;
}
.list-of-attendees {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 0 auto;
	min-width: 300px;
	text-decoration: none;

}
.list-of-attendees > div {
	display: flex;
	flex-direction: row;
	align-self: center;
	margin: 7px;
	width: 90%;
	background-color: rgb(71, 71, 71);
	color: antiquewhite;
	text-decoration: none;
	border: none;
	border-radius: 10px;
	padding: 8px 16px;
	text-align:start;
	font-weight: bold;
	font-size: min(2.5vw, 24px);
	transition-duration: 0.4s;
	cursor: pointer;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-of-attendees > div:hover {
	color: rgb(89, 89, 89);
	text-shadow: 1px 1px 2px rgb(128, 0, 124);
}

.link {
	text-decoration:none;
}

.attendee-card {
	text-decoration: none;
}

.close {
	color: red;
	text-shadow: 2px 1px 2px maroon;
	font-weight: bold;
}

.text {
	font-weight: bold;
}
