body {
    background-color: rgb(0, 0, 0);
    font-size: 16px; /* Set a default font size for the body */
  }
  
  @media (max-width: 600px) { /* Adjust layout for small screens */
    .manage-page {
      min-width: auto; /* Remove minimum width constraint */
      border-radius: 0; /* Remove border radius */
    }
    .user-attendees {
      display: block; /* Display elements as blocks */
      margin: 10px; /* Reduce margin */
      padding: 10px; /* Reduce padding */
    }
    .location-container, .name-container {
      text-align: center; /* Center text */
    }
    .input-container > input {
      width: 100%; /* Make input fields full-width */
    }
    .edit-button, .delete-button {
      font-size: 16px; /* Reduce font size */
      width: 100%; /* Make buttons full-width */
    }
  }
  .logo {
    width: 100%;
    max-width: 500px;
  }
  
  @media (min-width: 600px) {
    .logo {
      width: auto;
    }
  }
.manage-page{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin: auto;
    min-width: 480px;
    max-width: 960px;
    background-color: rgb(35, 35, 35);
    border-radius: 10px;
    border: 0px solid rgb(60, 0, 255);
}
.user-attendees{
    display: grid;
    grid-template-columns:2fr 2fr;
    margin: 20px;
    background-color: rgb(65, 65, 65);
    padding: 20px 30px;
    border-radius: 5px;
    border: 0px solid red;
}
#message-container{
    margin-bottom: 0px;
    font-weight: 100;
    text-shadow: 10;
    font-weight: bold;
    color: olive
}
.location-container, .name-container{
    text-align: left;
}
.manage-labels{
    padding: 5px 18px;
    font-size: 19px;
    color: white;
}
.current-field-desc{
    color: yellowgreen;
}
.current-field-value{
    color: white;
}
.input-container {
    padding: 5px 10px;
}
.input-container > input{
    text-indent: 5px;
    width: 240px
}
.button-container{
    display: grid;
    grid-column: span 2;
}
.edit-button, .delete-button{
    background-color: rgb(17, 67, 17);
    color: antiquewhite;
    margin: auto;
    margin-top: 10px;
    padding: 8px;
    text-align: center;
    font-size: 20px;
    width: 95%;
    transition-duration: 0.4s;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.edit-button:hover{
    background-color: green;
    color: darkslategray;
}
.delete-button{
    background-color: darkred;
    color: rgb(255, 180, 130);
}
.delete-button:hover{
    background-color: rgb(255, 180, 130);
    color: darkred;
}


