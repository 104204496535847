/* *{
    border: 0px solid red;
} */
body{
    background-color: black;
}
.wrap-div{
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 10px;
}
.register-page{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: rgb(35, 35, 35);
    margin: 0px auto;
    margin-bottom: 10px;
    padding: 10px;
    padding-bottom: 20px;
    border-radius: 5px;
}
.flex-item{
    width: 480px;
    margin: 20px 10px 0px 10px;
}
.register-form-container{
    justify-content: center;
    margin-top: 0px;
    padding: 5px 0 25px 0;
    background-color: rgb(65, 65, 65);
    border-radius: 5px;
    min-height: auto;
    max-width: 80vw;
    min-width: 40vw;
}
.register-header{
    font-size: 24px;
    color: white;
    margin: 20px auto;
    font-weight: bold;
    text-shadow: 1px 1px 2px antiquewhite;

}

.register-header2{
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.register-form{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    width: 100%;
    margin: auto;
}
.register-form > div {
    display: grid;
    justify-self: center;
}
.register-form > div > input{
    width: 240px;
    margin: 10px 0 10px 16px;
    text-align: center;
}
.register-button {
    display: grid;
    margin: auto;
    width: 30%;
    font-size: 24px;
    padding: 10px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .register-button {
        width: 80%; /* Adjust width on smaller screens */
        font-size: 18px; /* Optionally adjust font size on smaller screens */
    }
}

.name{
   
    align-self: center;
}

.gender {
    height: 40px; 
    margin-top: 10px;
    margin-bottom: 10px;
  }

.date-time{
    background-color: white;
    color: black;
    height: 40px;
    max-width: 290px;
   font-size: 19px;
   margin-top: 10px;
   margin-left: 10px;

}



.logo {
    width: 100%;
    max-width: 500px;
  }
  
  @media (min-width: 600px) {
    .logo {
      width: auto;
    }
    
  }