.leaflet-container {
    height: 400px;
    width: 600px;
  }



@media (max-width: 480px) {
    .leaflet-container {
      height: 180px;
      width: 280px;
    }
  }