.attendee-detail-container{
    display: flex;
	flex-direction: column;
	align-items: center;
}

.attendee-detail{
    display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(80, 52, 89);
	min-height: auto;
    max-width: 80vw;
    min-width: 40vw;
	margin: 0 10px 60px 10px;
	border-radius: 15px;
	
    
}
.attendee-info {
	border: 2px solid antiquewhite;
	margin: 4px;
	padding: 4px;
	background-color: gray;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
	color: black;
	
}

.logo {
	margin-top: 10px;
}

.map{
margin: 20px;
margin-top: 3px;
}

.key {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	border: 2px solid antiquewhite;
	padding: 8px;
	color: black;
	background-color: gray;
	font-weight: bold;
  }


  .box {
	width: 20px;
	height: 20px;
	border: 1px solid white;
	margin: 5px;
  }

  .red {
	background-color: red;
  }

  .blue {background-color: blue;}

/* add this media query for smaller screens */
@media (max-width: 767px) {
	.attendee-detail {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	}
  
	/* adjust the width of the logo */
	.attendee-detail img {
	  width: 80%;
	  max-width: 300px;
	}
  
	/* adjust the width of the attendee image */
	.attendee-detail .img img {
	  width: 60%;
	  max-width: 300px;
	}

  
	.key {
		margin: 0px;
		height: 20%;
		padding: 4px;
		font-size: 60%;
	}
	.box {
		width: 10px;
		height: 10px;
	}
	.attendee-info {
		width: 80%;
		margin-right: 8px;
		margin-left: 8px;
		font-size: .8em;
		padding: 1px;

	}
  }

