.attendee-card {
	align-self: self-start;
	padding: 5px 0 10px 0;

}



.attendee-card > .link:hover {
	color: #f6ff00;

}
