.past-results-page {
	display: grid;
	justify-content: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.past-results-page-container-top {
	display: flex;
	flex-direction: column;
	grid-template-columns: auto 1fr;
	background-color: rgb(80, 52, 89);
	margin: 10px;
	padding: 20px;
	border-radius: 15px;
}
.past-results-logo {
	display: grid;
}
.past-results-logo > img {
	height: min(32vw, 300px);
}

.description-container {
	display: flex;
	flex-direction: column;
	height: min(32vw, 150px);
	border-radius: 4px;
	background-color: rgb(119, 153, 125);
}

.event-caption {
	padding-bottom: 10px;
}
.button-div {
	align-self: center;
	padding: 5px 0 10px 0;
	text-decoration: none;
}

.button-div > .link {
	width: 80%;
	background-color: rgb(82, 97, 171);
	color: antiquewhite;
	border: none;
	border-radius: 10px;
	padding: 8px 16px;
	text-align: center;
	font-size: min(5vw, 24px);
	text-decoration: none;
	transition-duration: 0.4s;
	cursor: pointer;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-shadow: 2px 1px 2px black;
	font-weight: bold;
}

.button-div > .link:hover {
	color: rgb(65, 65, 65);
	text-decoration: none;
}

.button-div > .dead {
	width: 80%;
	background-color: darkslategray;
	color: antiquewhite;
	border: none;
	border-radius: 10px;
	padding: 8px 16px;
	text-align: center;
	font-size: min(5vw, 24px);
	text-decoration: none;
	transition-duration: 0.4s;
	cursor: pointer;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-shadow: 2px 1px 2px black;
	font-weight: bold;
}

.button-div > .dead:hover {
	color: red;
	text-decoration: none;
}

.submitTitle{
    font-size: 24px;
    color: white;
    margin: 20px auto;
    font-weight: bold;
    text-shadow: 1px 1px 2px antiquewhite;

}


.registration-container > div > button:hover {
	background-color: rgb(39, 53, 12);
	color: darkslategray;
}
.past-results-page-list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(69, 48, 73);
	min-height: auto;
	margin: 0 10px 30px 10px;
	border-radius: 15px;
}
.event-desc-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 15px;
	max-width: 600px;
	line-height: 1.2;
	border-radius: 16px;
}
.event-desc-header,
.attendees-header {
	display: flex;
	margin-top: 8px;
	font-size: 24px;
	text-align: left;
	font-weight: bold;
}
.event-desc-body {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 16px;
	padding: 0 10px;
	text-shadow: none;
	/* max-height: 550px;
    overflow: auto; */
}
p {
	margin: 8px 0;
}
.attendees-container {
	display: flex;
	flex-direction: column;
	align-content: center;
	border-radius: 0;
	min-width: min-content;
	align-items: center;
}

.message-container {
	color: rgb(235, 225, 33);
	font-weight: bold;
	font-size: min(10.5vw, 44px);
	margin: 5px;
}
.list-container-overflow {
	background-color: rgb(65, 65, 65);
	border-radius: 5px;
	min-height: 400px;
	max-height: 600px;
	overflow: auto;
	margin-bottom: 20px;
}
.list-of-attendees {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 0 auto;
	min-width: 300px;
	text-decoration: none;

}
.list-of-attendees > div {
	display: flex;
	flex-direction: row;
	align-self: center;
	margin: 7px;
	width: 90%;
	background-color: rgb(71, 71, 71);
	color: antiquewhite;
	text-decoration: none;
	border: none;
	border-radius: 10px;
	padding: 8px 16px;
	text-align:start;
	font-weight: bold;
	font-size: min(2.5vw, 24px);
	transition-duration: 0.4s;
	cursor: pointer;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-of-attendees > div:hover {
	color: rgb(89, 89, 89);
	text-shadow: 1px 1px 2px rgb(128, 0, 124);
}

.link {
	text-decoration:none;
}

.attendee-card {
	text-decoration: none;
}

.close {
	color: red;
	text-shadow: 2px 1px 2px maroon;
	font-weight: bold;
}

.text {
	font-weight: bold;
}
